<template>
  <v-row no-gutters class="pa-3" style="background-color: white">
    <v-col cols="12" class="mb-5">
      <h3 class="text-center mb-2">1. 원금 상환</h3>
    </v-col>

    <v-col cols="12">
      <p class="mb-2 font-size--20">
        선택된 동산:
        <template v-if="dongsan">
          {{ dongsan.title }} (<span class="font-weight-black">{{
            dongsan.root
          }}</span
          >) - 모채권: {{ dongsan.parent }} 호
        </template>
        <template v-else> 없음 </template>
      </p>
    </v-col>

    <v-col cols="8" class="pr-2">
      <v-simple-table style="border: 1px solid #eeeeee">
        <template v-slot:default>
          <thead>
            <tr>
              <th width="10%" class="text-center">호수</th>
              <th width="60%" class="text-center">상품명</th>
              <th width="30%" class="text-center">상환예정액</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="child in dongsanChildren"
              :key="`${child.idx}-child-rpy`"
            >
              <td class="text-center" style="white-space: nowrap">
                {{ child.idx }} 호
              </td>
              <td class="text-center">
                {{ child.title }}
              </td>
              <td class="text-center" :key="`${child.idx}-rpy-${child.rpy}`">
                <template v-if="child.rpy">
                  {{ child.rpy | commaFormat }}
                </template>
                <template v-else> 0 </template>
                원
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <p class="red--text mt-2 mb-1">* 원금만 상환할 수 있습니다.</p>
      <p class="red--text mb-1">
        * 펀다가 이미 상환시킨 동산 상품(온리진, 블루크러쉬)도 총 상환금액을
        입력합니다.
      </p>
      <p class="red--text mb-1">
        * 이자 지급 및 마지막 상환은 불가하므로 문의바랍니다.
      </p>
      <p class="red--text mb-1">
        * 이자 지급의 경우 지급하면 안되는 유저가 있습니다. (펀다로 귀속)
      </p>
      <p class="red--text mb-2">
        * 아래 상품 상환표에 아무것도 나타나지 않으면 상환이 불가합니다.
      </p>
    </v-col>

    <v-col cols="4">
      <v-text-field
        v-model.number="repaymentForm.principal"
        outlined
        :hint="`${$options.filters.commaFormat(repaymentForm.principal)} 원`"
        persistent-hint
        label="상환원금 합"
      >
      </v-text-field>
    </v-col>

    <v-col cols="12">
      <v-btn
        class="mt-3"
        large
        :dark="!isDisabled"
        :loading="isLoading"
        :disabled="isDisabled"
        @click="doRepay()"
      >
        원금 상환하기
      </v-btn>
    </v-col>

    <v-col cols="12">
      <keep-alive>
        <ExecRepaymentDiff
          :repayments="repayments"
          @emitGetRepayments="refreshRepayments"
        />
      </keep-alive>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    dongsan: {
      type: Object,
      default: null,
    },
  },
  components: {
    ExecRepaymentDiff: () =>
      import('@/components/funda/funding/dongsan/ExecRepaymentDiff.vue'),
  },
  data() {
    return {
      isLoading: false,
      repaymentForm: {
        principal: null,
      },
      dongsanChildren: [],
      repayments: [],
    }
  },
  watch: {
    dongsan: {
      immediate: true,
      deep: true,
      handler(v) {
        this.repaymentForm.principal = 0
        this.dongsanChildren = v?.children || []
        this.getRepayments()
      },
    },
    'repaymentForm.principal'(v) {
      const tmp = this.dongsanChildren.map((dongsan) => {
        dongsan.rpy = this.getRpyAmt(v, dongsan)
        return dongsan
      })
      this.dongsanChildren = tmp
    },
  },
  computed: {
    sumTotal() {
      const total = this.dongsanChildren.reduce((prev, next) => {
        prev += next.totalAmount
        return prev
      }, 0)
      return total
    },
    isDisabled() {
      return (
        this.isLoading ||
        !this.repaymentForm.principal > 0 ||
        !this.repayments?.length > 0
      )
    },
  },
  methods: {
    getRpyAmt(totalPrincipal, dongsan) {
      if (!!totalPrincipal) {
        const rate = dongsan.totalAmount / this.sumTotal
        return Math.floor(totalPrincipal * rate)
      } else {
        return 0
      }
    },
    async getRepayments() {
      if (this.repayments === null || this.repayments.length > 0) {
        return
      }
      const idxes = this.dongsanChildren.map((c) => c.idx)

      if (idxes.length < 1) {
        return
      }

      this.isLoading = true
      try {
        const q = `data: getIsNotExecutedFundingDataRepayments(fundingDataIdxes: $input) {
          idx
          fundingDataIdx
          totalAmount
          investUserPrincipal
          investUserTotalAmount
          investUserCount
          diffAmount
          isRepaidDiffAmount
          isExecuted
          createTime
        }`

        const data = await this.$fundaApi.query(
          gql`{${q}}`,
          'call($input: [Int]!)',
          { input: idxes }
        )

        if (data.length > 0) {
          this.repayments = data
        } else {
          this.repayments = null
        }
      } finally {
        this.isLoading = false
      }
    },
    async refreshRepayments() {
      this.repayments = []
      await this.getRepayments()
    },
    async doRepay() {
      if (this.repayments.length < 1) {
        return
      }

      const confirm = await this.$swal.basic.confirm('원금상환을 진행합니다.')
      if (confirm.isConfirmed === false) {
        return
      }

      this.isLoading = true
      const q = `repayDongsan(parentIdx: ${this.dongsan.parent}, principal: ${this.repaymentForm.principal})`
      await this.$fundaApi.mutation(gql`{${q}}`)
      this.repaymentForm.principal = 0
      this.refreshRepayments()
      this.isLoading = false
    },
  },
}
</script>
